<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="ประวัติการซื้อแบ่งตามประเภทสินค้า"
        :filter="filter"
        @search="handleSearch"
        @clear="clearFilter"
      >
        <template v-slot:filter-option>
          <b-col>
            <InputText
              textFloat="หมายเลขสมาชิก (Member ID)"
              placeholder="หมายเลขสมาชิก (Member ID)"
              v-model="filter.memberId"
              type="text"
              name="memberId"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.memberName"
              textFloat="ชื่อสมาชิก (Member Name)"
              placeholder="ชื่อสมาชิก (Member Name)"
              type="text"
              name="name"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputText
              v-model="filter.telephone"
              textFloat="เบอร์โทรศัพท์ (Telephone)"
              placeholder="เบอร์โทรศัพท์ (Telephone)"
              type="text"
              name="telephone"
              className="mb-2"
              @onEnter="handleSearch"
            />
          </b-col>
          <b-col>
            <InputDatePickerFilter
              textFloat="วันเริ่มต้น (Purchase From)"
              name="fromDate"
              ref="fromDate"
              v-model="filter.fromDate"
              @input="changeFromDate"
              :v="$v.filter.fromDate"
              placeholder="DD/MM/YYYY"
              class="mb-2"
            />
          </b-col>
          <b-col>
            <InputDatePickerFilter
              textFloat="วันสิ้นสุด (Purchase To)"
              name="toDate"
              ref="toDate"
              v-model="filter.toDate"
              @input="onChangeToDate"
              :v="$v.filter.toDate"
              placeholder="DD/MM/YYYY"
              class="mb-2"
            />
          </b-col>
        </template>
      </HeaderPanel>
      <div class="bg-white border-red mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="category.fields"
              :items="category.items"
              :busy="category.isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
              foot-clone
            >
              <template v-slot:cell(cat_code)="data">
                <router-link
                  :to="{
                    path: '/report/ref-sale-member',
                    query: {
                      ah: data.item.ah_2_id,
                      startDate: filter.startDate
                        ? $moment(filter.fromDate).format('YYYY-MM-DD')
                        : null,
                      endDate: filter.toDate
                        ? $moment(filter.toDate).format('YYYY-MM-DD')
                        : null,
                      telephone: filter.telephone,
                      memberId: filter.memberId
                    }
                  }"
                >
                  <u> {{ data.item.cat_code }}</u>
                </router-link>
              </template>
              <template v-slot:cell(quantity)="data">
                <div>{{ data.item.quantity | numeral('0,0') }}</div>
              </template>
              <template v-slot:cell(amount)="data">
                <div>{{ data.item.amount | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
              <template v-slot:foot()="data">
                <div v-if="stat.footer[data.field.key]" class="table-footer">
                  <p v-if="data.field.key == 'quantity'">
                    {{ stat.footer[data.field.key] | numeral('0,0') }}
                  </p>
                  <p v-else>
                    {{ stat.footer[data.field.key] | numeral('0,0.00') }}
                  </p>
                </div>
                <div v-else>
                  <p v-if="data.field.key == 'quantity'">
                    {{ stat.footer[data.field.key] | numeral('0,0') }}
                  </p>
                  <p v-else-if="data.field.key == 'amount'">
                    {{ stat.footer[data.field.key] | numeral('0,0.00') }}
                  </p>
                  <p
                    class="text-left px-2 table-footer"
                    v-else-if="data.field.text"
                  >
                    {{ data.field.text }}
                  </p>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
      <br />
      <div class="bg-white border-red mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="categoryDetail.fields"
              :items="categoryDetail.items"
              :busy="categoryDetail.isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main border-top"
              :no-border-collapse="false"
            >
              <template v-slot:cell(quantity)="data">
                <div>{{ data.item.quantity | numeral('0,0') }}</div>
              </template>
              <template v-slot:cell(amount)="data">
                <div>{{ data.item.amount | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(visit_date)="data">
                <div>
                  {{
                    $moment(data.item.visit_date)
                      .add(543, 'years')
                      .format($formatDateNewFull)
                  }}
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="categoryDetail.rows"
          @pagination="pagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from '@/components/loading/OtherLoading';
import HeaderPanel from '@/components/HeaderPanel';
import Pagination from '@/components/Pagination';
import InputSelect from '@/components/inputs/InputSelect';
import InputText from '@/components/inputs/InputText';
import InputDatePickerFilter from '@/components/inputs/InputDatePickerFilter';
import { mapGetters } from 'vuex';
import { minValue, maxValue } from 'vuelidate/lib/validators';

const minDate = (value, fromDate) => {
  if (!value) return true;
  let minDate = fromDate;
  return new Date(value) >= new Date(minDate);
};
const maxDate = (value, toDate = null) => {
  let maxDate = toDate || new Date();
  return new Date(maxDate) >= new Date(value);
};

export default {
  components: {
    OtherLoading,
    Pagination,
    HeaderPanel,
    InputSelect,
    InputText,
    InputDatePickerFilter
  },
  name: 'ReportHistorySaleCategory',
  data() {
    return {
      category: {
        fields: [
          {
            key: 'cat_code',
            label: 'Category Code',
            text: 'Total'
          },
          {
            key: 'ah_2_name',
            label: 'AH2 Desc',
            tdClass: 'text-left'
          },
          {
            key: 'ah_4_name',
            label: 'AH4 Desc',
            tdClass: 'text-left'
          },
          {
            key: 'quantity',
            label: 'Quantity'
          },
          {
            key: 'amount',
            label: 'Amount'
          }
        ],
        items: [],
        isBusy: false,
        rows: 0
      },
      filter: {
        branchId: 0,
        page: 1,
        take: 10,
        fromDate: null,
        toDate: null,
        memberName: '',
        telephone: '',
        memberId: ''
      },
      categoryDetail: {
        fields: [
          {
            key: 'invoice_no',
            label: 'Ref Bill'
          },
          {
            key: 'visit_date',
            label: 'Purchase Date'
          },
          {
            key: 'quantity',
            label: 'Total Quantity'
          },
          {
            key: 'amount',
            label: 'Amount Spend'
          }
        ],
        items: [],
        isBusy: false,
        rows: 0
      },
      isLoadingData: false,
      pageOptions: [
        { value: 10, text: '10 / page' },
        { value: 30, text: '30 / page' },
        { value: 50, text: '50 / page' },
        { value: 100, text: '100 / page' }
      ],
      selected: 0,
      isLoading: true,
      today: null,
      stat: {
        footer: {
          quantity: 0,
          amount: 0
        }
      }
    };
  },
  validations: {
    filter: {
      fromDate: {
        maxValue(val, { toDate }) {
          return maxDate(val, toDate);
        }
      },
      toDate: {
        minValue(val, { fromDate }) {
          return minDate(val, fromDate);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      branchId: 'getBranchId'
    })
  },
  created: async function () {
    this.filter.memberId = this.$route.query.memberId || '';

    this.today = this.$moment().format('YYYY-MM-DDT00:00:00');
    this.filter.fromDate = this.$moment()
      .startOf('year')
      .format('YYYY-MM-DDT00:00:00');
    await this.getHistorySaleByCategory();
    await this.getHistorySaleByCategoryDetail();
    this.isLoadingData = false;
  },
  methods: {
    getHistorySaleByCategory: async function () {
      this.$v.filter.$touch();
      if (this.$v.filter.$error) return;

      this.category.isBusy = true;
      let payload = { ...this.filter };
      payload.skip = 0;
      payload.take = 0;
      payload.from_date = payload.fromDate;
      payload.to_date = payload.toDate;
      payload.member_name = payload.memberName;
      payload.member_id = payload.memberId;

      await this.axios
        .post(`${this.$baseUrl}/report/get_history_sale_by_category`, payload)
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.category.items = data.detail;
            this.setAmount();
          }
        });

      this.category.isBusy = false;
    },
    setAmount() {
      this.stat.footer.amount = this.category.items.reduce(
        (n, { amount }) => n + amount,
        0
      );
      this.stat.footer.quantity = this.category.items.reduce(
        (n, { quantity }) => n + quantity,
        0
      );
    },
    getHistorySaleByCategoryDetail: async function () {
      this.categoryDetail.isBusy = true;
      let payload = { ...this.filter };
      payload.skip = (payload.page - 1) * payload.take;
      payload.from_date = payload.fromDate;
      payload.to_date = payload.toDate;
      payload.member_name = payload.memberName;
      payload.member_id = payload.memberId;

      await this.axios
        .post(
          `${this.$baseUrl}/report/get_history_sale_by_category_detail`,
          payload
        )
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.categoryDetail.items = data.detail;
            this.categoryDetail.rows = data.total_count;
          }
        });

      this.categoryDetail.isBusy = false;
    },
    handleSearch() {
      this.pagination(1);
    },
    pagination(page) {
      this.filter.page = page;
      this.getHistorySaleByCategory();
      this.getHistorySaleByCategoryDetail();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getHistorySaleByCategory();
      this.getHistorySaleByCategoryDetail();
    },
    clearFilter() {
      this.$v.filter.$reset();
      this.filter = {
        page: 1,
        take: 10,
        fromDate: null,
        toDate: null,
        memberName: '',
        telephone: '',
        memberId: ''
      };
      this.getHistorySaleByCategory();
      this.getHistorySaleByCategoryDetail();
    },
    changeFromDate(val) {
      this.filter.fromDate = val;
    },
    onChangeToDate(val) {
      this.filter.toDate = val;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .table-main {
  table {
    min-width: max-content;
  }
}
::v-deep .table-main.border-top {
  thead {
    border-top: 1px solid #111;
  }
}
.time {
  color: #afafaf;
}
.line-clamp2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.badge {
  font-size: 85%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  max-width: max-content;
  font-weight: 100;
}
.badge-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.icon {
  font-size: 24px;
  color: #000 !important;
}
.table-footer {
  color: #333 !important;
  text-align: center;
}
</style>
